<template>
  <div class="font-arial text-new-black py-5 cursor-pointer" :class="{'border-b border-hi-lighgrey' : border}">
    <div class="w-full flex flex-row justify-between">
      <div>{{ listName }}</div>
      <div class="underline cursor-pointer" @click="$emit('button-clicked')">Bearbeiten</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myListItem',
  props: ['listName', 'listContent', 'border'],
  emits: ['button-clicked'],
};
</script>
