<template>
<transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
  <div v-if="showOverlay === 'editUserNickname'" class="w-full h-auto md:h-full fixed bottom-0 md:inset-0 z-30 flex flex-row justify-center items-center pointer-events-none font-arial text-new-black">
    <div class="w-full md:w-auto md:min-w-1/3 h-auto md:h-auto bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 pb-10 md:p-12 pointer-events-auto">
      <div class="mt-2 md:mt-0 font-faro font-bold text-3xl">Namen ändern</div>
      <input v-model="newName" type="text" :placeholder="name" class="bg-white border-hi-lightgrey w-full p-5 text-center rounded-lg appearance-none outline-none mt-5" />
      <div class="w-full mt-5">
        <customButton label="Namen ändern" @click="changeUserName()" />
      </div>
      <div v-if="errorMessage !== null" class="text-red-500 p-5">{{ errorMessage }}</div>

    </div>
  </div>
</transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import customButton from '../../../symbols/customButton.vue';

export default {
  name: 'editUserNickname',
  props: ['name'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { customButton },
  emits: ['button-clicked'],

  data() {
    return {
      errorMessage: null,
      newName: '',
    };
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    updateUserName() {
      return this.$store.state.updateUserName;
    },
    queryMeEx() {
      return this.$store.state.queryMeEx;
    },
    userDataId() {
      return this.$store.state.profile.me.userData.data.id;
    },
    myName() {
      return `${this.$store.state.profile.me.userData.data.attributes.Vorname} ${this.$store.state.profile.me.userData.data.attributes.Nachname}`;
    },
  },

  watch: {
    showOverlay() {
      if (this.showOverlay === null) {
        this.errorMessage = null;
      }
    },
  },

  methods: {

    changeUserName() {
      this.updateUserName({ id: this.userDataId, nickname: this.newName.trim() === '' ? this.myName : this.newName }).then((result) => {
        if (result.error) {
          if (result.error.message.includes('match')) this.errorMessage = 'Der Name darf keine Sonderzeichen enthalten.';
          else this.errorMessage = result.error;
        } else {
          this.queryMeEx();
          this.hideEdit();
        }
      });
    },

    hideEdit() {
      this.iosVibrate(0);
      this.errorMessage = null;
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
